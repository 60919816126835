
<template>
	<div class="swiperbox">
		<swiper class="swiper"  :options="swiperOption"  ref="mySwiper" >
			<swiper-slide v-for="item in list" :key="item.id" :data-jumpUrl="item.link">
				<div class="lunbox" style="margin-right:10px;" >
					<img :class="{ lunboimg: true, 'islink': item.link != null }"     :src="item.pic_url"
						alt="">
				</div>
			</swiper-slide>

		</swiper>
		<div class="swiper-button-prev" slot="button-prev"></div>
		<div class="swiper-button-next" slot="button-next"></div>

		<div class="swiper-pagination"></div>

	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { lunbolist } from '@/api/website/lunbo.js'

import 'swiper/css/swiper.css'

export default {
	name: 'swiper-example-navigation',
	title: 'Navigation',
	components: {
		Swiper,
		SwiperSlide
	},

	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	data() {
 const _this = this
		return {
			list: [],
			swiperOption: {
				initialSlide: 1,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				},
				loop: true,
				autoplay: {
					delay: 8000,
					disableOnInteraction: false //true是点击swiper之后不切换（默认行为）
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				},

				on:{
					click:(e)=>{

						// 通过$refs获取对应的swiper对象
            let swiper = this.$refs.mySwiper.$swiper;
            let index = swiper.clickedIndex //当前点击的索引
						this.bannerJump(index)

						// console.log('e',e)
						// let url = e.target.dataset.jumpurl // jumpurl是在swiper-slide中动态绑定的data-jumpUrl属性，值是从后台获取的跳转链接
        		// this.bannerJump(url)
					}
				}

			}
		}



	},
	watch: {
		site: {
			handler(ov, nv) {
				this.getLunboList()
			},
			deep: true
		}
	},

	mounted() {
		this.getLunboList()
	},

	methods: {
		//去广告页面
		bannerJump(index) {
			let current  = index-1
			const length = this.list.length;
			if(current>(length-1)){
				current = 0
			}
			const url = this.list[current].link
			if(url){
				window.open(url)
			}
			

		},


		getLunboList() {
			const params = { site_id: this.site.id }
			lunbolist(params).then(res => {
				this.list = res.data
				// console.log('====', res)
			})
		}

	}

}
</script>

<style lang="scss" scoped>
.swiperbox {
	position: relative;
	min-width:  1200px;
}

.islink {
	cursor: pointer;
}

.swiper-pagination {

	// position: absolute;
	// left: 0;
	// right: 0;
	top: 320px;
	z-index: 11;
	background: rgba(0, 0, 0, 0.2);
	border-radius: 20px;
	width: auto;
	display: inline-block;
	padding: 8px;
	transform: translateX(-50%);

	::v-deep .swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		background: rgba(255, 255, 255, 1);
		margin: 0 3px;
	}

	::v-deep .swiper-pagination-bullet-active {
		width: 30px;
		height: 10px;
		background: #FFFFFF;
		border-radius: 5px;
	}

}

.swiper-button-prev {
	position: absolute;

	left: calc(50% - 600px);
	top: 170px;
	width: 34px;
	height: 66px;
	background: url('../../assets/images/pre.png') no-repeat;
	background-size: contain;
	color: rgba(255, 255, 255, 0)
}



.swiper-button-next {
	position: absolute;
	left: calc(50% + 566px);
	top: 170px;
	width: 34px;
	height: 66px;
	background: url('../../assets/images/nex.png') no-repeat;
	background-size: contain;
	color: rgba(255, 255, 255, 0)
}


.swiper {

	/* 设置按钮大小 */
}

.swiper {
	height: 360px;
}

.lunbox {
	position: relative;
	width: 100%;
	height: 360px;
	overflow: hidden;

	.lunboimg {
		// width: 2680px;
		min-width: 100%;
		height: 360px;

		display: inline-block;
		/* 以下为图片能居中的主要代码，需父级user-img相对定位 position:relative; */
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>