<template>
	<div class="IndexZhaoshang">
		<div class="itemTitle">
			<div class="titlebox">
				<div class="subject">
					<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
				</div>
				<div class="more" @click="toList">
					<span>更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
				</div>
			</div>

			<div class="zscon">
				<div class="lzs" @click="toDetail(top.id)">
					<img class="lcover" :src="top.cover_url" alt="">
					<div class="zstitlebox">
						<div class="title">{{ top.title }}</div>
						<div class="time">{{ top.created_at }}</div>
					</div>
					<div class="intro">{{ top.summary }}</div>
				</div>
				<div class="rzs">
					<div :class="{ 'listitem': true }" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
						<div class="coverbox">

							<img class="cover" :src="item.cover_url" alt="">
						</div>
						<div class="content">
							<div class="topcon">
								<div class="title">{{ item.title }}</div>
								<!-- <div class="summary">{{ item.summary }}</div> -->
							</div>
							<div class="create_at">{{ item.created_at }}</div>
						</div>

					</div>

				</div>
			</div>

		</div>
	</div>
</template>

<script>

import { zhaoshanglist } from '@/api/website/zhaoshang.js'
export default {
	name: 'PcWebsitezhaoshang',

	data() {
		return {
			tpttTitle: '',
			list: [],
			top: {}
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				this.initLists()
			}
		}
	},

	mounted() {
		this.initTitle()
		this.initLists()
	},

	methods: {


		toList() {
			const tourl = '/' + this.site.path + '/list/zhaoshang'
			this.$router.push(tourl)
		},

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/zhaoshang/' + id
			// this.$router.push(tourl)

			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},

		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/zstj.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_zstj.png")
			}
		},

		initLists() {

			zhaoshanglist({ site_id: this.site.id }).then(res => {
				this.top = res.data.top
				this.list = res.data.list
			})

		}


	},
};
</script>

<style lang="scss" scoped>
.zscon {
	height: 360px;

	margin-left: 20px;
	display: flex;

	.lzs {
		height: 360px;
		width: 572px;
		cursor: pointer;

		.lcover {
			width: 572px;
			height: 280px;
		}

		.zstitlebox {
			height: 34px;
			display: flex;
			justify-content: space-between;

			.title {
				flex: 1;
				line-height: 34px;
				text-align: left;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #000000;
				white-space: nowrap;
				overflow: hidden; //溢出内容隐藏
				text-overflow: ellipsis; //文本溢出部分用省略号表示
			}

			.time {
				width: 110px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #A0A0A0;
				line-height: 34px;

			}
		}

		.intro {
			height: 40px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #A0A0A0;
			line-height: 20px;
			text-align: left;
			text-overflow: -o-ellipsis-lastline;
			overflow: hidden; //溢出内容隐藏
			text-overflow: ellipsis; //文本溢出部分用省略号表示
			display: -webkit-box; //特别显示模式
			-webkit-line-clamp: 2; //行数
			line-clamp: 2;
			-webkit-box-orient: vertical; //盒子中内容竖直排列

		}
	}

	.rzs {
		height: auto;
		width: 568px;
		margin: 0 20px;

		.listitem {
			cursor: pointer;
			width: 568px;
			height: 75px;
			display: flex;
			align-items: center;
			margin-bottom: 14px;

			.coverbox {
				width: 130px;
				height: 75px;

				.cover {
					width: 130px;
					height: 75px;
					object-fit: cover;
				}
			}


			.content {

				width: 428px;
				height: 75px;
				width: 600px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-start;
				margin-left: 10px;

				.topcon {
					width: 428px;

					.title {
						text-align: left;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #000000;

						height: 44px;
						font-size: 16px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #000000;
						line-height: 22px;
						overflow: hidden; //溢出内容隐藏
						text-overflow: ellipsis; //文本溢出部分用省略号表示
						display: -webkit-box; //特别显示模式
						-webkit-line-clamp: 2; //行数
						line-clamp: 2;
						-webkit-box-orient: vertical; //盒子中内容竖直排列


					}

					.created_at {
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #A0A0A0;
					}



				}
			}


		}

		.page1:first-child {
			width: 790px;
			height: 232px;
			display: flex;
			align-items: center;
			border-bottom: solid 1px #F2F2F2;

			.coverbox {
				width: 320px;
				height: 192px;

				.cover {
					width: 320px;
					height: 192px;
					object-fit: cover;
				}
			}


			.content {
				width: 454px;
				height: 192px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-start;
				margin-left: 10px;

				.topcon {
					width: 454px;

					.title {
						text-align: left;
						height: 28px;
						font-size: 20px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #000000;
						line-height: 28px;
						overflow: hidden; //超出隐藏
						white-space: nowrap; //不折行
						text-overflow: ellipsis; //溢出显示省略号

					}


					.summary {
						text-align: left;
						height: 40px;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #848484;
						line-height: 20px;
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden; //溢出内容隐藏
						text-overflow: ellipsis; //文本溢出部分用省略号表示
						display: -webkit-box; //特别显示模式
						-webkit-line-clamp: 6; //行数
						line-clamp: 6;
						-webkit-box-orient: vertical; //盒子中内容竖直排列
					}

				}
			}


		}


	}

}

.IndexZhaoshang {
	padding-top: 10px;
	width: 1200px;
	height: 464px;
	background: #FFFFFF;
	box-sizing: border-box;

	.itemTitle {
		height: 84px;
		width: 100%;


		.titlebox {
			// background:red;
			box-sizing: border-box;
			height: 84px;
			margin: 0 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.subject {
				width: 170px;
				height: 44px;
				background: #FFFFFF;
				border-radius: 2px;
			}

			.more {
				width: 62px;
				height: 24px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #5B6980;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}

	}



}
</style>