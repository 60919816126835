<template>
	<div class="indexpage">
		
		<site-header></site-header>
		<header-lunbo></header-lunbo>
		<nav-menu></nav-menu>
		<div class="adbox" :style="{backgroundImage:'url('+ toutiaoad +')'}">
			<div class="toutiao" >
				<toutiao></toutiao>
				<zhibo></zhibo>
			</div>
		</div>
		
		<div class="gonggao">
			<index-gonggao></index-gonggao>
			<div class="gonggaoright">
				<div class="banner">
					<img class="bannerimg" @click="towindowurl" :src="banner.cover" alt="">
				</div>
				<new-gonggao></new-gonggao>
			</div>
		</div>
		<div class="zhaoshang">
			<zhaoshang></zhaoshang>
		</div>
		<div class="landdata">

			<landdata></landdata>
			<calendar></calendar>

		</div>
		<div class="hangpai">
			<hangpai></hangpai>
		</div>
		<div class="tuijie">
			<div class="ziliao">
				<ziliao></ziliao>
			</div>
			<div class="zhengce">
				<zhengce></zhengce>
				<zazhi></zazhi>

			</div>
		</div>
		<div v-if="site.path === 'cn'" class="jujian">
			<jujian></jujian>
		</div>
		<div class="pingtai">
			<div class="tuzhan">
				<tudizhan></tudizhan>
			</div>
			<div class="luntan">
				<fenghui></fenghui>
			</div>
		</div>

		<div class="ad" v-if="showad && (ad.status === 1)"  @click.prevent="toad">
			<i  class="close el-icon-circle-close" @click.stop="colsedad"></i>
			<img class="adimg" :src="ad.pic_url" alt="">		
		</div>
		<div class="ad1" v-if="showad1 && (ad1.status === 1)"  @click.prevent="toad1">
			<i  class="close el-icon-circle-close" @click.stop="colsedad1"></i>
			<img class="adimg" :src="ad1.pic_url" alt="">	
		</div>

		<index-footer></index-footer>
		
	
	</div>
</template>

<script>

import SiteHeader from '@/components/website/SiteHeader.vue'
import Zhaoshang from '@/components/website/Zhaoshang.vue'
import Landdata from '@/components/website/Landdata.vue'
import Calendar from '@/components/website/Calendar.vue'
import HeaderLunbo from '@/components/website/HeaderLunbo.vue'
import IndexGonggao from '@/components/website/IndexGonggao.vue'
import NewGonggao from '@/components/website/NewGonggao.vue'
import Toutiao from '@/components/website/Toutiao.vue'
import Zhibo from '@/components/website/Zhibo.vue'
import NavMenu from '@/components/website/NavMenu.vue'
import IndexFooter from '@/components/website/IndexFooter.vue'
import Ziliao from '@/components/website/Ziliao.vue'
import Zhengce from '@/components/website/Zhengce.vue'
import Jujian from '@/components/website/Jujian.vue'
import Tudizhan from '@/components/website/Tudizhan.vue'
import Fenghui from '@/components/website/Fenghui.vue'
import Hangpai from '@/components/website/Hangpai.vue'
import Zazhi from '@/components/website/Zazhi.vue'


import { detail ,ad,toutiaoad} from '@/api/website/tradesystem.js'


export default {
	name: 'PcWebsiteIndex',
	
	components: {
		SiteHeader,
		HeaderLunbo,
		NavMenu,
		IndexGonggao,
		NewGonggao,
		Toutiao,
		Zhibo,
		IndexFooter,
		Zhaoshang,
		Landdata,
		Calendar,
		Ziliao,
		Zhengce,
		Jujian,
		Tudizhan,
		Fenghui,
		Hangpai,
		Zazhi
	},
	
	data() {
		return {
			ad:{},
			ad1:{},
			showad:true,
			showad1:true,
			banner: {},
			toutiaoad:''
		};
	},

	computed: {
		site: {
			get() {
				if (this.$store.state.site == null) {
					const site = { id: 5, name: "总站", dcity_id: null, city_plate_id: 0, path: "cn", company_name: "江苏土拍信息科技有限公司", address: "总站", phone: "15206213421", adviser: "总站", copyright: "总站", status: 1, created_at: "2022-10-26 09:57:52", updated_at: "2022-10-27 14:37:49", sort: 1, current: false }
					this.$store.dispatch('site', site);

					return site
				} else {
					return this.$store.state.site
				}

			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	// {"id":5,"name":"总站","dcity_id":null,"city_plate_id":0,"path":"cn","company_name":"江苏土拍信息科技有限公司","address":"总站","phone":"15206213421","adviser":"总站","copyright":"总站","status":1,"created_at":"2022-10-26 09:57:52","updated_at":"2022-10-27 14:37:49","sort":1,"current":false}


	watch: {
		site: {
			handler(v) {
				this.init()
			},
			deep: true
		},



	},
	mounted(){
		this.getad()
		this.gettoutiaoad()
	},
	methods: {
		gettoutiaoad(){
			toutiaoad().then(res=>{
				this.toutiaoad = res.data.pic_url
			})
		},

		colsedad(){
			this.showad = false
		},

		colsedad1(){
			this.showad1 = false
		},
		toad(){
			window.open(this.ad.link)
		},

		toad1(){
			if(this.ad1.link!==''){
				window.open(this.ad1.link)
			}
			
		},

		getad(){
			ad().then(res=>{
				 this.ad = res.data

				 this.ad1 = {
						status:1,
						link:'',
						pic_url:'https://tupaibase.chinatupai.com/zhaopin.jpg'
				 }

			})
		},


		//跳到外链
		towindowurl() {
			window.location.href = this.banner.link;
		},

		init() {
			// alert(this.site.id)
			detail({ site_id: this.site.id }).then(res => {
				this.banner = res.data
			})
		}



	},
};
</script>

<style lang="scss" scoped>
.ad{
	z-index:2000;
	position: fixed;
	left:10px;
	bottom: 10px;
	width:200px;
	height:150px;
	.adimg{
		width:200px;
		height:150px;
	}
	.close{
		z-index:2001;
		position: absolute;
		top:10px;
		right:10px;
		font-size:20px;
		// color:#fff;
		cursor: pointer;

	}
}

.ad1{
	z-index:2000;
	position: fixed;
	right:10px;
	bottom: 10px;
	width:200px;
	height:150px;
	.adimg{
		width:200px;
		height:150px;
	}
	.close{
		z-index:2001;
		position: absolute;
		top:10px;
		right:10px;
		font-size:20px;
		// color:#fff;
		cursor: pointer;

	}
}

.pingtai {
	width: 1200px;
	height: 424px;
	margin: 0 auto;
	margin-top: 40px;
	display: flex;

	.tuzhan {
		width: 795px;
		height: 424px;
		background: #FFFFFF;
	}

	.luntan {
		margin-left: 15px;
		width: 390px;
		height: 424px;
		background: #FFFFFF;
	}
}

.jujian {
	width: 1200px;
	height: 618px;
	background: #FFFFFF;
	margin: 0 auto;
	margin-top: 40px;
}

.tuijie {
	width: 1200px;
	height: 614px;
	display: flex;
	margin: 0 auto;
	margin-top: 40px;


	.ziliao {
		width: 795px;
		height: 614px;
		background: #FFFFFF;
	}

	.zhengce {
		margin-left: 15px;
		width: 390px;
		height: 614px;
		// background: #FFFFFF;
	}
}



.indexpage {
	min-width:1200px;
	background: #F0F3F8;
}
.adbox{
	padding-top: 70px;
	background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
	.toutiao {
		height: 434px;
		width: 1200px;
		margin: 0 auto;
		// background: yellow;
		display: flex;
		}
}


.gonggao {
	margin-top: 40px;
	height: 508px;
	width: 1200px;
	margin: 0 auto;
	margin-top: 40px;
	display: flex;

	.gonggaoright {
		width: 390px;
		margin-left: 15px;
		height: 508px;

		.banner {
			width: 390px;
			height: 104px;
			// background: red;

			.bannerimg {
				width: 390px;
				height: 104px;
				cursor: pointer;
			}
		}

	}
}

.zhaoshang {
	margin-top: 40px;
	height: 464px;
	width: 1200px;
	margin: 0 auto;
	margin-top: 40px;

}

.landdata {
	margin-top: 40px;
	height: 520px;
	width: 1200px;
	margin: 0 auto;
	margin-top: 40px;
	display: flex;

}

.hangpai {
	margin-top: 40px;
	height: 560px;
	width: 1200px;
	background: #fff;
	margin: 0 auto;
	margin-top: 40px;

}
</style>