<template>
	<div class="indexzhengce">
		<div class="itemTitle">
			<div class="titlebox">
				<div class="subject">
					<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
				</div>
				<div class="more" @click="toList">
					<span>更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
				</div>
			</div>

			<div class="citybtn">
				<div @click="tocity(item)" :class="{ 'btn': true, 'checkedbtn': item.checked }" v-for="item in citylist"
					:key="item.city_id">{{
						item.city_name
					}}</div>
			</div>
			<div class="linebox">
				<div class="line" @click="downfile(item)" v-for="item in 	list" :key="item.id">
					<div class="showname">
						{{ item.name }}
					</div>
					<div class="showtext">{{ item.created }}</div>
				</div>
			</div>



		</div>


	</div>
</template>

<script>
import { zhengcelist } from '@/api/website/zhengce.js'
export default {
	name: 'PcWebsitezhengce',

	data() {
		return {
			tpttTitle: '',
			top: {},
			list: [],
			citylist: [],
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				this.initLists()
			}


		}
	},

	mounted() {
		this.initTitle()
		this.initLists()
	},

	methods: {
		tocity(item) {
			this.cityid = item.city_id
			this.initLists()
		},
		//下载文件
		downfile(item) {
			window.open(item.file_url, 'top')
		},


		toList() {
			const tourl = '/' + this.site.path + '/list/zhengce'
			this.$router.push(tourl)
		},

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/zhengce/' + id
			// this.$router.push(tourl)

			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},

		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/cszc.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_cszc.png")
			}
		},

		initLists() {

			const params = { site_id: this.site.id }
			if (this.cityid) {
				params.city_id = this.cityid
			}
			zhengcelist(params).then(res => {
				this.list = res.data.file_list
				this.citylist = res.data.city_list
			})

		}


	},
};
</script>

<style lang="scss" scoped>

.line {
	cursor: pointer;
	width: 356px;
	height: 48px;
	border-bottom: solid 1px #F0F3F8;
	display: flex;

	.showrank {
		width: 48px;
		display: flex;
		justify-content: center;
		align-items: center;

		.rank {
			width: 20px;
			height: 20px;
			line-height: 20px;
			background: #E77817;
			color: #fff;
			font-weight: bold;
			font-size: 10px;
			border-radius: 4px;
		}

	}

	.showname {
		flex: 1;
		margin-left: 10px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		height:48px;
		line-height: 48px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;


	}


	.showtext {

		width: 78px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		height: 48px;
		line-height: 48px;

	}

}

.line:last-child {
	border-bottom: none;
}


.citybtn {
	width: 350px;
	margin-left: 20px;
	min-height: 42px;
	// height: 84px;
	max-height: 84px;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;

	// box-sizing: border-box;
}

.btn {
	// width: 40px;
	height: 28px;
	padding: 0 10px;
	line-height: 28px;
	background: #F2F2F2;
	border-radius: 2px;
	margin-right: 10px;
	margin-top: 10px;
	cursor: pointer;
}

.checkedbtn {
	background: #00A3E4;
	color: #fff;
}



.indexzhengce {
	padding-top: 10px;
	// width: 795px;
	height: 414px;
	background: #fff;
	box-sizing: border-box;

	.itemTitle {
		// height: 84px;
		width: 100%;
		display: flex;
		flex-direction: column;


		.titlebox {
			// background:red;
			box-sizing: border-box;
			height: 84px;
			margin: 0 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.subject {
				width: 170px;
				height: 44px;
				background: #FFFFFF;
				border-radius: 2px;
			}

			.more {
				width: 62px;
				height: 24px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #5B6980;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}

	}



}


.linebox {
	width: 356px;
	margin: 0 auto;
}



.firstpic {
	width: 250px;
	object-fit: cover;
	height: 258px;
	cursor: pointer;
}

.listall {

	margin-left: 10px;
	// background: red;
	flex: 1;
	height: 258px;
	// width: 100%;
	width: 500px;

	.listitem {
		width: 100%;

		height: 25px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 25px;
		text-align: left;
		margin-bottom: 18px;
		margin-top: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}
}
</style>