<template>
	<div class="landata">
		<div class="indexlanddata">
			<div class="top">
				<div class="subject">
					<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
				</div>
				<div class="citybtn">
					<div @click="tocity(item)" :class="{ 'btn': true, 'checkedbtn': item.checked }" v-for="item in citylist"
						:key="item.city_id">{{
							item.city
						}}</div>
				</div>

			</div>

			<div class="totalarea" @click="toBigdata" style="cursor:pointer">
				<div class="gdp totalitem">
					<div class="totaltitle">
						<span class="year">{{ gdp.year }}</span>地区生产总值(GDP)
					</div>
					<div class="totalvalue">
						{{ gdp.value }}
						<span class="unit">亿元</span>
					</div>
				</div>
				<div class="fenge"></div>
				<div class="income totalitem">
					<div class="totaltitle">
						<span class="year">{{ income.year }}</span>一般公共预算收入
					</div>
					<div class="totalvalue">
						{{ income.value }}
						<span class="unit">亿元</span>
					</div>
				</div>
			</div>
		</div>
		<div class="barbox">
			<div class="bartitlebox">
				<div class="kong"></div>
				<div class="title">
					<div class="titletop">{{ title }}</div>
					<div class="titlebottom">{{ remarks }}</div>
				</div>
				<div class="more" style="z-index:10" @click="toBigdata">
					<span>更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
				</div>
			</div>
			<div class="bar">
				<website-bar :data="bardata" :showField="['acreage','buildacreage', 'totalprice']"></website-bar>
			</div>
		</div>

	</div>
</template>

<script>
import { landdatalist, landdata } from '@/api/website/landdata'

import WebsiteBar from '@/components/echarts/WebsiteBar.vue'
export default {
	components: { WebsiteBar },
	name: 'PcWebsiteIndexlanddata',

	data() {
		return {
			tpttTitle: '',
			citylist: [],

			currentcityid: null,
			title: '',
			remarks: '',
			gdp: {
				year: '',
				value: 0
			},
			income: {
				year: '',
				value: 0
			},
			bardata: {}


		}
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				this.initList()
			}
		}
	},


	mounted() {

	},

	methods: {


		// toMore(type) {
		// 	// alert(1)
		// 	const tourl = '/' + this.site.path + '/list/landdata/' + type + '/' + this.currentcityid
		// 	this.$router.push(tourl)
		// },


		toBigdata() {

			window.location.href = process.env.VUE_APP_LOGIN_INDEX_DOMAIN

		},

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/landdata/' + id
			// this.$router.push(tourl)

			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},




		tocity(item) {
			const dcityid = item.city_id
			landdata({ dcity_id: dcityid }).then(res => {
				this.citylist = this.citylist.map(item => {
					if (item.city_id === dcityid) {
						item.checked = true
					} else {
						item.checked = false
					}
					return item
				})
				this.gdp = res.data.gdp
				this.income = res.data.income
			})

		},


		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/tpsj.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_tpsj.png")
			}
		},

		//初始化列表
		initList() {
			const params = {
				site_id: this.site.id
			}
			if (this.currentcityid) {
				params.city_id = this.currentcityid
			}
			landdatalist(params).then(res => {



				const currentcity = res.data.citylist.filter(item => {
					return item.checked === true
				})

				if (this.site.path === 'cn') {
					this.citylist = res.data.citylist
					this.currentcityid = currentcity[0].city_id
				} else {
					this.citylist = []
				}


				this.gdp = res.data.gdp
				this.income = res.data.income
				this.title = res.data.title
				this.remarks = res.data.remarks
				this.bardata = res.data.bardata

			})
		}

	},
};
</script>

<style lang="scss" scoped>
.barbox {
	padding-top: 11px;
	margin-top: 4px;
	width: 795px;
	height: 329px;
	background: #FFFFFF;

	.bar {
		position: relative;
		top: -30px;
	}

	.bartitlebox {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.kong {

		width: 62px;
		height: 24px;
		background: #FFFFFF;

	}

	.title {
		flex: 1;

		.titletop {
			height: 22px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 22px;

		}

		.titlebottom {
			height: 22px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 22px;

		}
	}

	.more {
		width: 62px;
		height: 24px;
		background: #FFFFFF;
		border-radius: 2px;
		border: 1px solid #5B6980;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-right: 20px;
	}

}


.landata {
	height: 520px;
	width: 795px;
	// background:#fff;
}

.item {
	cursor: pointer;
	display: flex;
	height: 48px;
	align-items: center;
	line-height: 48px;
	justify-content: space-between;
	margin: 0 20px;
	border-bottom: solid 1px #F0F3F8;

	.titlearea {
		display: inline-block;
		width: 280px;
		height: 48px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 48px;
		text-align: left;
		overflow: hidden; //超出的文本隐藏
		text-overflow: ellipsis; //溢出用省略号显示
		white-space: nowrap; // 默认不换行；

	}

	.timearea {
		width: 82px;
		height: 17px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		line-height: 17px;
	}
}


.churang {
	width: 395px;
	height: 400px;
	background: #ffffff;

	margin-top: 4px;
}

.chengjiao {
	width: 396px;
	height: 400px;
	background: #ffffff;
	margin-top: 4px;
	margin-left: 4px;
}

.indexlanddata {
	height: 187px;
	width: 795px;
	background: #FFFFFF;
	display: flex;
	flex-direction: column;

	.top {
		display: flex;
	}

	.totalarea {
		display: flex;
		justify-content: space-around;
		align-content: center;

		.totalitem {
			width: calc(50% - 1px);
		}

		.totaltitle {
			height: 28px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 22px;
			letter-spacing: 1px;
			font-weight: bold;
			.year {
				font-size: 13px;
				font-weight: normal;
			}
		}

		.totalvalue {
			height: 30px;
			font-size: 30px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #00A3E4;
			line-height: 30px;

			.unit {
				font-size: 14px;
				font-weight: normal;
				display: inline-block;
				line-height: 30px;
				position: relative;
				top: -5px;
			}
		}

		.fenge {
			margin-top: 10px;
			width: 2px;
			height: 50px;
			background: #F0F3F8;
			border-radius: 1px;
		}
	}

	.subject {
		width: 170px;
		height: 44px;
		background: #FFFFFF;
		border-radius: 2px;
		margin: 30px 20px;
	}

	.citybtn {
		width: 585px;
		height: 84px;
		display: flex;
		flex-wrap: wrap;
	}

	.btn {
		// width: 40px;
		height: 28px;
		padding: 0 6px;
		line-height: 28px;
		background: #F2F2F2;
		border-radius: 2px;
		margin-right: 12px;
		margin-top: 36px;
		cursor: pointer;
	}

	.checkedbtn {
		background: #00A3E4;
		color: #fff;
	}



}

.more {
	width: 62px;
	height: 24px;
	background: #FFFFFF;
	border-radius: 2px;
	border: 1px solid #5B6980;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 20px;
}
</style>