<template>
	<div>
		<div class="indexgonggao">
			<div class="subject">
				<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
			</div>
			<div class="citybtn">
				<div @click="tocity(item)" :class="{ 'btn': true, 'checkedbtn': item.checked }" v-for="item in citylist"
					:key="item.city_id">{{
		item.city
}}</div>
			</div>
		</div>
		<div class="conbox">
			<div class="churang">
				<div class="toptitle">
					<div class="bluetitle">
						<div class="blue"></div>
						<div class="title">出让公告</div>
					</div>
					<div class="more"  @click="toList(1)">
						<span>更多</span>
						<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
					</div>
				</div>
				<div class="item" v-for="item in sell" :key="item.id" @click="toDetail(item.id)">
					<div class="titlearea">
						<img v-if="item.is_new"
							style="display:inline-block;width:48px;margin-right:10px;position: relative;top:5px;"
							src="@/assets/images/new.png" alt="">
						{{ item.title }}
					</div>
					<div class="timearea">{{ item.created_at }}</div>
				</div>

			</div>
			<div class="chengjiao">
				<div class="toptitle">
					<div class="bluetitle">
						<div class="blue"></div>
						<div class="title">成交公告</div>
					</div>
					<div class="more" @click="toList(2)">
						<span >更多</span>
						<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
					</div>
				</div>
				<div class="item" v-for="item in deal" :key="item.id" @click="toDetail(item.id)">
					<div class="titlearea">

						{{ item.title }}
					</div>
					<div class="timearea">{{ item.created_at }}</div>
				</div>

			</div>
		</div>

	</div>

</template>

<script>
import { list } from '@/api/website/notice'
export default {
	name: 'PcWebsiteIndexGonggao',

	data() {
		return {
			tpttTitle: '',
			citylist: [],
			currentcityid: null,
			sell: [],
			deal: []
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.currentcityid = null
			
				this.initTitle()
				this.initList()
			}
		}
	},


	mounted() {

	},

	methods: {

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/gonggao/' + id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},
		



		tocity(item) {
			this.currentcityid = item.city_id
			this.initList()
		},
		toList(type) {
			const tourl = '/' + this.site.path + '/list/gonggao/' + type + '/' + this.currentcityid
			this.$router.push(tourl)
		},

		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/tpgg.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_tpgg.png")
			}
		},

		//初始化列表
		initList() {
			const params = {
				site_id: this.site.id
			}
			if (this.currentcityid) {
				params.city_id = this.currentcityid
			}
			// this.sell =[]
			// this.deal =[]
			list(params).then(res => {
				const citylist = res.data.city_list
				const currentcity = citylist.filter(item => {
					return item.checked === true
				})

				this.citylist  = citylist
				this.currentcityid = currentcity[0].city_id

				this.sell = res.data.sell
				this.deal = res.data.deal
			})
		}

	},
};
</script>

<style lang="scss" scoped>
.item {
	cursor: pointer;
	display: flex;
	height: 48px;
	align-items: center;
	line-height: 48px;
	justify-content: space-between;
	margin: 0 20px;
	border-bottom: solid 1px #F0F3F8;

	.titlearea {
		display: inline-block;
		width: 280px;
		height: 48px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 48px;
		text-align: left;
		overflow: hidden; //超出的文本隐藏
		text-overflow: ellipsis; //溢出用省略号显示
		white-space: nowrap; // 默认不换行；

	}

	.timearea {
		width: 82px;
		height: 17px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		line-height: 17px;
	}
}

.conbox {
	display: flex;
	height: 58px;


	.chengjiao {

		.toptitle {
			height: 58px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.bluetitle {
				display: flex;

				.blue {
					margin-left: 20px;
					margin-right: 10px;
					width: 4px;
					height: 28px;
					background: rgba(0, 163, 228, 0.4);
				}

				.title {
					width: 80px;
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #333640;
					line-height: 28px;
				}

			}
		}


	}

	.churang {
		.toptitle {
			height: 58px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.bluetitle {
				display: flex;

				.blue {
					margin-left: 20px;
					margin-right: 10px;
					width: 4px;
					height: 28px;
					background: rgba(0, 163, 228, 0.4);
				}

				.title {
					width: 80px;
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #333640;
					line-height: 28px;
				}

			}
		}



	}
}

.churang {
	width: 395px;
	height: 400px;
	background: #ffffff;

	margin-top: 4px;
}

.chengjiao {
	width: 396px;
	height: 400px;
	background: #ffffff;
	margin-top: 4px;
	margin-left: 4px;
}

.indexgonggao {
	height: 104px;
	width: 795px;
	background: #FFFFFF;
	display: flex;

	.subject {
		width: 170px;
		height: 44px;
		background: #FFFFFF;
		border-radius: 2px;
		margin: 30px 20px;
	}

	.citybtn {
		width: 585px;
		height: 104px;
		display: flex;
		flex-wrap: wrap;
		// background:red;
		align-items: center;
		align-content:space-evenly;;
	}

	.btn {
		
		// width: 40px;
		padding:0 6px;
		height: 24px;
		line-height: 24px;
		background: #F2F2F2;
		border-radius: 2px;
		margin-right: 12px;
		// margin-top: 16px;
		cursor: pointer;
	}

	.checkedbtn {
		background: #00A3E4;
		color: #fff;
	}



}

.more {
	width: 62px;
	height: 24px;
	background: #FFFFFF;
	border-radius: 2px;
	border: 1px solid #5B6980;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 20px;
}
</style>