<template>
	<div class="picbox">
		<img :src="siteinfo.zazhi_pic_url" alt="" @click="tolink">
	</div>
</template>

<script>
import {sitelist} from '@/api/website/site'
export default {
	name: 'PcWebsiteXuanfu',

	data() {
		return {
			siteinfo:{
				zazhi_link:'',
				zazhi_pic_url:''
			}
		}
	},

	mounted() {
		
		sitelist().then(res=>{
			this.siteinfo = res.data[0]
		})
	},

	methods: {
		tolink(){
			window.open(this.siteinfo.zazhi_link)
		}

	},
};
</script>

<style lang="scss" scoped>
.picbox{
	margin-top:4px;
	width: 390px;
	height: 196px;
	background: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
	img{
		width: 358px;
		height: 164px;
	}
}

</style>