import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'


//公告列表
export function list(params) {
	return loginRequest({
    url: '/frontend/website/notice/home-list',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//detail 详情
export function detail(params) {
	return loginRequest({
    url: '/frontend/website/notice/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}



//列表
export function gonggaolist(params) {
	return loginRequest({
    url: '/frontend/website/notice/list',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//首页最新公告列表
export function newgonggao(params) {
	return loginRequest({
    url: '/frontend/website/notice/new',
		method: 'post',
		data:Qs.stringify(params)
	})
}
