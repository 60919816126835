<template>

	<div>


		<div v-if="iscn" class="newgonggao">
			<div class="toptitle">
				<div class="bluetitle">
					<div class="blue"></div>
					<div class="title">最新公告</div>
				</div>
				
				<div class="more"  @click="toList(3)">
					<span>更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
				</div>
			</div>
			<div class="item" v-for="item in newgonggao" :key="item.id" @click="toDetail(item.id)">
				<div class="titlearea">
					<img style="display:inline-block;width:48px;margin-right:10px;position: relative;top:5px;"
						src="@/assets/images/new.png" alt="">
					{{ item.title }}
				</div>
				<div class="timearea">{{ item.created_at }}</div>
			</div>
		</div>
		<div v-if="!iscn" class="paihang">
			<div class="toptitle">
				<div class="bluetitle">
					<div class="orange"></div>
					<div class="title">{{ title }}</div>
				</div>

			</div>
			<div class="btnarea">
				<div :class="{ 'btn': true, 'btnchecked': btnchecked === 'money' }" @click="changeBtn('money')">拿地金额</div>
				<div :class="{ 'btn': true, 'btnchecked': btnchecked === 'area' }" @click="changeBtn('area')">拿地面积</div>
			</div>
			<div class="moneyara">
				<div class="phheader">
					<div class="hp hp1">排名</div>
					<div class="hp hp2">企业</div>
					<div class="hp hp3">{{ this.runkname }}</div>
				</div>
				<div class="linebox">
					<div class="line" v-for="item in 	list" :key="item.rank">
						<div class="showrank">
							<div class="rank">{{ item.rank }}</div>
						</div>
						<div class="showname">
							{{ item.name }}
						</div>
						<div class="showtext">{{ item.totalprice }}</div>
					</div>
				</div>
			</div>


		</div>

	</div>
</template>

<script>

import { newgonggao } from '@/api/website/notice'
import { area, money } from '@/api/website/paihang'


export default {
	name: 'PcWebsiteNewGonggao',

	data() {
		return {
			btnchecked: 'money',
			title:'',
			list:[],
			moneylist: [],
			arealist: [],
			newgonggao: [],
			runkname:'金额(亿元)',
			iscn: false
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler(v) {
				const path = v.path
				if (path === 'cn') {
					this.iscn = true
					this.initlist()
				} else {
					this.iscn = false
					this.initsite()

				}
			}
		}
	},

	mounted() {

	},

	methods: {

		changeBtn(string) {
			this.btnchecked = string
			if (string === 'money') {
				this.list = JSON.parse(JSON.stringify(this.moneylist))
				this.runkname = '金额(亿元)'
			} else {
				let arealist = JSON.parse(JSON.stringify(this.arealist))
				this.runkname = '面积(万方)'
				arealist =  arealist.map(item=>{
					item.totalprice = item.totalacreage
					return item
				})

				this.list = arealist
			}
				
		},
		//初始化分站数据
		initsite() {

			money({ dcity_id: this.site.dcity_id }).then(res => {
				this.moneylist = res.data.money
				this.title = res.data.title
				this.list = JSON.parse(JSON.stringify(this.moneylist))
			})

			area({ dcity_id: this.site.dcity_id }).then(res => {
				this.arealist = res.data.area
				this.title = res.data.title
			})

		},

		toDetail(id) {
			const tourl = '/cn/detail/gonggao/' + id
			// this.$router.push(tourl)

			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},

		toList(type) {
			const tourl = '/cn/list/gonggao/' + type + '/0'
			this.$router.push(tourl)
		},

		initlist() {
			newgonggao().then(res => {
				this.newgonggao = res.data
			})
		}

	},

};
</script>

<style lang="scss" scoped>
.paihang {
	width: 390px;
	height: 400px;
	background: #FFFFFF;
	margin-top: 4px;

	.toptitle {
		height: 68px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.bluetitle {
			display: flex;

			.orange {
				margin-left: 20px;
				margin-right: 10px;
				width: 4px;
				height: 28px;
				background: rgba(231, 120, 23, 0.4);
			}


			.title {
				height: 28px;
				font-size: 20px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #E77817;
				line-height: 28px;
			}

		}
	}

	.btnarea {
		height: 28px;
		display: flex;
		justify-content: space-around;
		align-items: center;


		.btn {
			width: 160px;
			height: 28px;
			background: #F2F2F2;
			border-radius: 2px;
			font-size: 12px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;

			line-height: 28px;
			font-weight: 400;
			color: #000000;
			cursor: pointer;

		}

		.btnchecked {
			width: 160px;
			height: 28px;
			background: #E77817;
			border-radius: 2px;
			font-size: 12px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #FFFFFF;
			line-height: 28px;
		}

	}

	.phheader {
		margin: 10px auto;
		width: 356px;
		height: 34px;
		background: rgba(0, 163, 228, 0.2);
		display: flex;
		margin-bottom: 5px;

		.hp1 {
			width: 48px;
			height: 34px;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 34px;
		}

		.hp2 {
			flex: 1;
			height: 34px;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 34px;
			text-align: left;
			padding-left: 40px;
		}

		.hp3 {
			width: 86px;
			height: 34px;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 34px;
		}

	}

	.linebox {
		width: 356px;
		margin: 0 auto
	}

	.line {
		width: 356px;
		height: 50px;
		border-bottom: solid 1px #F0F3F8;
		display: flex;

		.showrank {
			width: 48px;
			display: flex;
			justify-content: center;
			align-items: center;

			.rank {
				width: 20px;
				height: 20px;
				line-height: 20px;
				background: #E77817;
				color: #fff;
				font-weight: bold;
				font-size: 10px;
				border-radius: 4px;
			}

		}

		.showname {
			flex: 1;
			margin-left: 10px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			height: 50px;
			line-height: 50px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-align: left;


		}

		.showtext {
			width: 66px;
			height: 50px;
			line-height: 50px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			text-align: right;
			margin-right: 20px;
		}

	}
	.line:last-child{
		border-bottom: none;
	}



}

.more {
	width: 62px;
	height: 24px;
	background: #FFFFFF;
	border-radius: 2px;
	border: 1px solid #5B6980;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	margin-right: 20px;
}

.item {
	cursor: pointer;
	display: flex;
	height: 48px;
	align-items: center;
	line-height: 48px;
	justify-content: space-between;
	margin: 0 20px;
	border-bottom: solid 1px #F0F3F8;

	.titlearea {
		display: inline-block;
		width: 280px;
		height: 48px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 48px;
		text-align: left;
		overflow: hidden; //超出的文本隐藏
		text-overflow: ellipsis; //溢出用省略号显示
		white-space: nowrap; // 默认不换行；

	}

	.timearea {
		width: 82px;
		height: 17px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		line-height: 17px;
	}
}

.newgonggao {
	width: 390px;
	height: 400px;
	background: #FFFFFF;
	margin-top: 4px;


	.toptitle {
		height: 58px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.bluetitle {
			display: flex;

			.blue {
				margin-left: 20px;
				margin-right: 10px;
				width: 4px;
				height: 28px;
				background: rgba(0, 163, 228, 0.4);
			}


			.title {
				width: 80px;
				height: 28px;
				font-size: 20px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #333640;
				line-height: 28px;
			}

		}
	}
}




</style>