<template>
	<div class="zhibo">
		<div class="titlebox">
			<div class="subject">
				<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
			</div>
			<div class="more"  @click="toList">
				<span>更多</span>
				<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
			</div>
		</div>
		<div class="zhibolist">
			<div class="tuwen" v-for="item in top" :key="item.id" @click="toDetail(item)">
				<div class="tu">
					<img class="pic" :src="item.pic" alt="">
				</div>
				<div class="wen">
					<div class="titlebox">
						<div class="title">
							<div class="status">
								<div :class="[item.classname]">
									<img v-if="item.statuspic" class="statuspic" :src="item.statuspic" alt="">
									<div class="statusname">{{ item.statusname }}</div>
								</div>
							</div>
							{{ item.title }}
						</div>
						<div class="showdate">{{ item.times }}</div>
					</div>

				</div>
			</div>
			<div class="list">
				<div class="listitem" v-for="item in list" :key="item.id" @click="toDetail(item)">
					<div class="titlebox">
						<div class="title">
							<div class="status">
								<div :class="[item.classname]">
									<img v-if="item.statuspic" class="statuspic" :src="item.statuspic" alt="">
									<div class="statusname">{{ item.statusname }}</div>
								</div>
							</div>
							{{ item.title }}
						</div>
						<div class="showdate">{{ item.times }}</div>
					</div>


				</div>
			</div>

		</div>

	</div>
</template>

<script>
import { zhibolist } from '@/api/website/zhibo.js'
export default {
	name: 'PcWebsiteZhibo',

	data() {
		return {
			tpttTitle: '',
			top: [],
			list: []
		};
	},

	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				this.initLists()
			}
		}
	},

	mounted() {
		this.initTitle()
		this.initLists()
	},
	methods: {
		toDetail(item){
			window.open(item.link)
		},

		

		toList() {
			const tourl = '/' + this.site.path + '/list/zhibo'
			
			this.$router.push(tourl)
		},

		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/tpzb.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_tpzb.png")
			}
		},

		handleestatus(top) {
			return top.map(item => {
				let statuspic = ''
				let classname = ''
				let statusname = ''
				switch (item.status) {
					case 0:
						statuspic = null
						statusname = '往期'
						classname = 'wangqi'
						
						break;
					case 2:
						statuspic = require("@/assets/images/zxgg.png")
						statusname = '最新预告'
						classname = 'zhiboyugao'
						break;
					case 1:
						statuspic = require("@/assets/images/zzzb.png")
						statusname = '正在直播'
						classname = 'zhengzaizhibo'
						break;
				}
				item.statuspic = statuspic
				item.classname = classname
				item.statusname = statusname
				return item
			})

		},

		initLists() {
			zhibolist({'site_id':this.site.id}).then(res => {
				const data = res.data
				const top = data.slice(0, 2)
				const list = data.slice(2, 6)

				this.top = this.handleestatus(top)
				this.list = this.handleestatus(list)
			})

		}

	}
};
</script>

<style lang="scss" scoped>
.zhiboyugao,
.zhengzaizhibo {
	width: 84px;
	height: 22px;
	line-height: 22px;
	background: #00A3E4;
	text-align: center;
	color: #fff;
	font-size: 12px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	.statuspic{
		height:16px;
		width:16px;
	}
}
.zhiboyugao{
	background: #E77817;
}

.tuwen {
	height: 80px;
	width: 350px;
	display: flex;
	margin: 0 auto;
	margin-bottom: 10px;

	.tu {
		width: 130px;
		height: 80px;
		// overflow: hidden;

		.pic {
			width: 130px;
			height: 80px;
			object-fit: cover;
			cursor: pointer;
		}

	}

	.wen {
		width: 210px;
		height: 80px;

		// display: flex;
		// flex-direction: column;
		// justify-content: space-between;
		// align-items: flex-start;
		.titlebox {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			align-items: flex-start;
			cursor: pointer;


			.title {
				display: inline-block;
				height: 50px;
				text-align: left;
				font-size: 16px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #000000;
				line-height: 22px;

				text-overflow: -o-ellipsis-lastline;
				overflow: hidden; //溢出内容隐藏
				text-overflow: ellipsis; //文本溢出部分用省略号表示
				display: -webkit-box; //特别显示模式
				-webkit-line-clamp: 2; //行数
				line-clamp: 2;
				-webkit-box-orient: vertical; //盒子中内容竖直排列


				.status {
					display: inline-block;

					.wangqi {
						width: 46px;
						height: 22px;
						line-height: 22px;
						border: solid 1px #00A3E4;
						border-radius: 4px;
						text-align: center;
						color: #00A3E4;
						font-size: 12px;
					}
				}

			}

			.showdate {
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #A0A0A0;
				line-height: 17px;
				text-align: left;
			}
		}


	}
}

.list {
	display: flex;
	flex-direction: column;

	.titlebox {
		// width: 100%;
		height: 44px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		overflow: hidden; //超出隐藏


		.title {
			display: inline-block;
			height: 25px;
			text-align: left;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 22px;
			overflow: hidden; //超出隐藏
			white-space: nowrap; //不折行
			text-overflow: ellipsis; //溢出显示省略号

			.status {
				display: inline-block;

				.wangqi {
					width: 46px;
					height: 22px;
					line-height: 22px;
					border: solid 1px #00A3E4;
					border-radius: 4px;
					text-align: center;
					color: #00A3E4;
					font-size: 12px;
				}
			}

		}

		.showdate {
			// overflow: hidden; //超出隐藏
			white-space: nowrap; //不折行


			// background:red;

			height: 26px;
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #A0A0A0;
			line-height: 26px;
			text-align: left;
			width: 80px;
			margin-right: 10px;
			text-align:right;
		}
	}
}


.zhibo {
	width: 390px;
	height: 434px;
	background: #fff;
	margin-left: 15px;
}

.titlebox {
	// background:red;
	box-sizing: border-box;
	height: 84px;
	margin: 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.subject {
		width: 170px;
		height: 44px;
		background: #FFFFFF;
		border-radius: 2px;
	}

	.more {
		width: 62px;
		height: 22px;
		background: #FFFFFF;
		border-radius: 2px;
		border: 1px solid #5B6980;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
}
.listitem{
	cursor: pointer;
	// padding-top:2px;
	box-sizing: border-box;
}
</style>