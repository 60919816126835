<template>
	<div class="indexziliao">
		<div class="itemTitle">
			<div class="titlebox">
				<div class="subject">
					<img :src="tpttTitle" style="width:100%;height:100%;" alt="">
				</div>
				<div class="more" @click="toList">
					<span>更多</span>
					<img style="width:12px;height:9px;margin-left:6px;" src="@/assets/images/more.png" alt="">
				</div>
			</div>

			<div class="ziliaoList">

				<div class="vlist" @click="toDownload(item)" v-for="item in list">
					<img class="coverimg" :src="item.cover_url" alt="" />
					<div class="title">{{ item.name }}</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>

import { ziliaolist } from '@/api/website/ziliao.js'
export default {
	name: 'PcWebsiteziliao',

	data() {
		return {
			tpttTitle: '',
			list: [],
			top: {}
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},
	watch: {
		site: {
			handler() {
				this.initTitle()
				this.initLists()
			}
		}
	},

	mounted() {
		this.initTitle()
		this.initLists()
	},

	methods: {
		//下载文件
		toDownload(item) {
			window.open(item.file_url, 'top')
		},


		toList() {
			const tourl = '/' + this.site.path + '/list/ziliao'
			this.$router.push(tourl)
		},

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/ziliao/' + id
			// this.$router.push(tourl)


			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')

		},

		//初始化头条样式
		initTitle() {
			if (this.site.path === 'cn') {
				this.tpttTitle = require("@/assets/images/tdtjzl.png")
			} else {
				this.tpttTitle = require("@/assets/images/fz_tdtjzl.png")
			}
		},

		initLists() {

			ziliaolist({ site_id: this.site.id }).then(res => {
				this.list = res.data
				// console.log(res.data)
			})

		}


	},
};
</script>

<style lang="scss" scoped>
.indexziliao {
	padding-top: 10px;
	width: 795px;
	height: 434px;
	background: #FFFFFF;
	box-sizing: border-box;

	.itemTitle {
		height: 84px;
		width: 100%;


		.titlebox {
			// background:red;
			box-sizing: border-box;
			height: 84px;
			margin: 0 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.subject {
				width: 218px;
				height: 44px;
				background: #FFFFFF;
				border-radius: 2px;
			}

			.more {
				width: 62px;
				height: 24px;
				background: #FFFFFF;
				border-radius: 2px;
				border: 1px solid #5B6980;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
			}
		}

	}



}

.ziliaoList {
	height: 298px;


	// padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;

	.vlist {

		width: 192px;
		height: 248px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top:8px;
		.coverimg {
			margin-left: 34px;
			width: 170px;
			height: 210px;
			border: 1px solid #C1C1C1;
			cursor: pointer;
		}

		.title {
			width: 170px;
			overflow: hidden;
			margin-left: 34px;
			cursor: pointer;
			width: 170px;
			height: 30px;
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #000000;
			line-height: 30px;
		}
	}

}

.firstpic {
	width: 250px;
	object-fit: cover;
	height: 258px;
	cursor: pointer;
}

.listall {

	margin-left: 10px;
	// background: red;
	flex: 1;
	height: 258px;
	// width: 100%;
	width: 500px;

	.listitem {
		width: 100%;

		height: 25px;
		font-size: 18px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 25px;
		text-align: left;
		margin-bottom: 18px;
		margin-top: 5px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		cursor: pointer;
	}
}
</style>